<template>
    <v-dialog
        v-model="pickerDialog"
        hide-overlay
        max-width="250px"
        persistent
        width="250px"
    >
        <v-form ref="propertyForm" lazy-validation>
            <v-card>
                <v-card-title class="px-4">{{ $t('message.addProductProperty') }}</v-card-title>
                <v-card-text class="px-4">
                    <v-autocomplete
                        :items="options[optionKey]"
                        :loading="loading.options"
                        :placeholder="$t('message.typeToSearch')"
                        :rules="[...validationRules.required]"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="ProductProperty.title"
                        item-value="ProductProperty.id"
                        loader-height="5"
                        ref="propertyComboBox"
                        return-object
                        solo
                        v-model="selectedProperty"
                        v-if="isPropertyConnected"
                    />
                    <v-combobox
                        :items="options[optionKey]"
                        :loading="loading.options"
                        :placeholder="$t('message.typeToSearch')"
                        :rules="[...validationRules.required]"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="ProductProperty.title"
                        item-value="ProductProperty.id"
                        loader-height="5"
                        ref="propertyComboBox"
                        return-object
                        solo
                        v-model="selectedProperty"
                        v-else
                    />
                    <span v-if="!isPropertyConnected" class="font-italic warning--text">{{ $t('message.freeTextAllowed') }}</span>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-2" @click="closePicker" small>{{ $t('message.cancel') }}</v-btn>
                    <v-btn @click="addProperty" :loading="loading.add" small color="info">{{ $t('message.add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { api } from 'Api';
import { mapGetters } from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
    name: "ProductPropertyPicker",
    props: ['property','productId','openDialog','supplierId'],
    data(){
        return {
            connectedProperties: [
                'Product.species',
                'Product.grade',
                'Product.origin_certification',
                'Product.market_certification',
                'Product.moisture_content'
            ],
            dialog: false,
            loading: {
                add: false,
                options: false
            },
            // properties: [],
            selectedProperty: null,
        }
    },
    computed: {
        ...mapFields('supplier',{
            options: 'options'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        isPropertyConnected() {
            if(this.connectedProperties.includes(this.property)) return true
            return false
        },
        optionKey() {
            let key = null
            switch(this.property){
                case 'Product.composition':
                    key = 'compositions'
                    break;
                case 'Product.density':
                    key = 'densities'
                    break;
                case 'Product.design':
                    key = 'designs'
                    break;
                case 'Product.drying_type':
                    key = 'dryingTypes'
                    break;
                case 'Product.finishing':
                    key = 'finishes'
                    break;
                case 'Product.glue':
                    key = 'glues'
                    break;
                case 'Product.grade':
                    key = 'grades'
                    break;
                case 'Product.length':
                    key = 'lengths'
                    break;
                case 'Product.moisture_content':
                    key = 'mcs'
                    break;
                case 'Product.market_certification':
                case 'Product.origin_certification':
                    key = 'certifications'
                    break;
                case 'Product.species':
                    key = 'species'
                    break;
                case 'Product.surface':
                    key = 'surfaces'
                    break;
                case 'Product.thickness':
                    key = 'thicknesses'
                    break;
                case 'Product.width':
                    key = 'widths'
                    break;
            }
            return key
        },
        pickerDialog: {
            get() {
                return this.dialog;
            },
            set(value){
                this.dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addProperty(){
            this.$refs.propertyComboBox.blur()
            this.$nextTick(() => {
                if(this.$refs.propertyForm.validate()) {
                    this.loading.add = true
                    let propertyObj = null
                    if (typeof this.selectedProperty === "string") {
                        propertyObj = {
                            ProductProperty: {
                                id: 'NEW',
                                title: this.selectedProperty,
                                property: this.property
                            }
                        }
                    } else {
                        propertyObj = this.selectedProperty
                        propertyObj['ProductProperty']['property'] = this.property
                    }
                    api
                        .put('/products/' + this.productId, {
                            property: propertyObj
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                this.$toast.success(this.$t('message.successes.propertyAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.$emit('property-added')
                            } else {
                                this.$toast.error(this.$t('message.errors.propertyNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            if (typeof this.selectedProperty === "string") {
                                this.options[this.optionKey] = []
                            }
                            this.loading.add = false
                            this.closePicker()
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.propertyNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })

                    this.pickerDialog = false
                    this.selectedProperty = null
                }
            })
        },
        closePicker(){
            this.$refs.propertyForm.reset()
            this.pickerDialog = false
            this.selectedProperty = null
        },
        loadList() {
            this.loading.options = 'warning'
            api
                .get('/products/properties/' + this.property + '/options', {
                    params: {
                        conditions: [
                            {
                                field: 'supplier_id',
                                value: this.supplierId
                            }
                        ]
                    }
                })
                .then((response) => {
                    if(response.data.status == 'success'){
                        this.options[this.optionKey] = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.options = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.options = false
                })
        },
        // resetList() {
        //     this.properties = []
        // }
    },
    watch: {
        openDialog(value) {
            if(value == true){
                this.options[this.optionKey] = []
                this.loadList()
            }
            this.selectedProperty = null
            this.dialog = value
        }
    }
}
</script>

<style scoped>

</style>